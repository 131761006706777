<template>
    <layout>
        <template slot="content">
            <router-view />
        </template>
    </layout>
</template>

<script>
import Layout from '@/views/layouts/Layout';

export default {
    name: 'ServiceModule',

    components: {
        Layout
    }
};
</script>
